import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  MIN_4K,
  MIN_2K,
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
  MOBILE_TOP_MENU_HEIGHT,
} from 'constants/sizes';

const WidePaddingsStyle = css`
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: ${MIN_2K}px) {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-top: 400px;
    padding-bottom: 400px;
  }
`;

const FullHeightPaddings = css`
  padding-top: 40px;
  padding-bottom: 40px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-bottom: 50px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: ${MOBILE_TOP_MENU_HEIGHT + 20}px;
    padding-bottom: 40px;
  }

  @media (min-width: ${MIN_2K}px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
`;

export const GridPaddings = css`
  padding-left: 80px;
  padding-right: 80px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: ${MIN_2K}px) {
    padding-left: 120px;
    padding-right: 120px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-left: 240px;
    padding-right: 240px;
  }
`;

export const gridGaps = css`
  gap: 20px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    gap: 16px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    gap: 20px;
  }

  @media (min-width: ${MIN_4K}px) {
    gap: 40px;
  }
`;

interface ILayout {
  withPaddings?: boolean;
  gridOnly?: boolean;
  widePaddings?: boolean;
  verticalOnly?: boolean;
}

const Layout = styled.div<ILayout>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(10, minmax(0, 1fr));

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    ${({ gridOnly = false }) => (gridOnly ? '' : css`
      padding-top: ${MOBILE_TOP_MENU_HEIGHT}px;
    `)};
  }

  @media (min-width: ${MIN_4K}px) {
    gap: 40px;
  }

  ${gridGaps}
  ${({
    withPaddings = false, verticalOnly = false,
  }) => (withPaddings && !verticalOnly ? FullHeightPaddings : '')};
  ${({ gridOnly = false }) => (gridOnly ? '' : GridPaddings)};
  ${({ widePaddings = false }) => (widePaddings ? WidePaddingsStyle : '')};
`;

export default Layout;
